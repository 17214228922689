<template>
  <SiteElement
    v-if="!isConditionalyHidden(el)"
    :id="el.id"
    :is-editor-mode="false"
    :type="el.type"
    :style="el.style"
    :hover="el.style.hover"
    :tag-name="el.tagName"
    :attr="el.attr"
    :config="el.options"
    :element="element"
    :page-data="pageData"
    :page-options="pageOptions"
    :locale="locale"
    v-bind="{ value: el.value }"
  >
    <template v-for="slotName in targetSlots" #[slotName]>
      <template v-for="sub in getElementsBySlot(el.elements, slotName)" :key="sub.id">
        <RecursiveElementWrapper
          v-if="sub.options.visible && !isConditionalyHidden(sub)"
          :el="sub"
          :locale="locale"
          :page-data="pageData"
          :page-options="pageOptions"
        />
      </template>
    </template>
  </SiteElement>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import type { Element as ElementType, PageOptions } from '../types/model';
import type { ElementTypes } from '../types/options';
import SiteElement from './index.vue';
import RecursiveElementWrapper from './wrapper.vue';
import { getElementsBySlot } from '@shared/utils';

const props = defineProps({
  el: { type: Object as PropType<ElementType<ElementTypes>>, default: () => ({}) },
  pageData: { type: Object as PropType<Record<string, any>>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' }
});

const element = computed(() => props.el);
const targetSlots = computed(() => {
  const newList = props.el.elements.map((sub) => sub.slotName || 'default');
  const slotList = new Set([...newList]);
  return [...slotList].filter(Boolean);
});

function isConditionalyHidden(el: ElementType<ElementTypes>) {
  return props.pageData?.hiddenConditionalRefs?.includes(el.conditionalRef);
}
</script>
